export default [
  {
    head: "Our Purpose",
    subhead: "We take your brand's image as our priority",
  },
  {
    head: "Tujuan Kami",
    subhead: "We take your brand's image as our priority",
  },
];
