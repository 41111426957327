export default [
  {
    nav1: "Home",
    nav2: "About Us",
    nav3: "Services",
    nav5: "Blog",
    nav4: "Contact Us",
  },
  {
    nav1: "Beranda",
    nav2: "Tentang Kami",
    nav3: "Layanan",
    nav5: "Blog",
    nav4: "Hubungi Kami",
  },
];
