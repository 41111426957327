export default [
  {
    head: "Get in Touch with Us",
    subhead: "PT. Sinar Media Digitama",
    addy: "Aria Putra 8, Serua Indah, Ciputat, South Tangerang",
    mail: "info@smdigitama.com",
    phone: "021 - 74634075",
  },
  {
    head: "Hubungi Kami",
    subhead: "PT. Sinar Media Digitama",
    addy: "Aria Putra 8, Serua Indah, Ciputat, Tangerang Selatan",
    mail: "info@smdigitama.com",
    phone: "021 - 74634075",
  },
];
