export default [
    {
        head : "Our Experiences",
        first : "Projects Handled",
        second : "Clients Satisfied"
    },
    {
        head : "Experience Kami",
        first : "Proyek yang ditangani",
        second : "Klien yang puas"
    }
]