export default [
  {
    title: "Our Services",
    desc: "Your Brand image is our priority",
  },
  {
    title: "Layanan Kami",
    desc: "We take your brand's image as our priority",
  },
];
