export default [
  {
    head: "Our Top Clients",
    subhead: "Finest Client",
  },
  {
    head: "Top Klien Kami",
    subhead: "Finest Client",
  },
];
