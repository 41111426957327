export default [
  {
    head: "We Take Your Brand's Image as Our Priority",
    nav1: "Home",
    nav2: "About Us",
    nav3: "Services",
    nav4: "Contact Us",
    subhead: "Get In Touch with Us",
    wa: "Contact us via Whatsapp",
    addy: "Ciputat South Tangerang",
  },
  {
    head: "Kami Mengambil Citra Merek Anda sebagai Prioritas Kami",
    nav1: "Beranda",
    nav2: "Tentang Kita",
    nav3: "Services",
    nav4: "Hubungi Kita",
    subhead: "Get In Touch with Us",
    wa: "Hubungi kami melalui Whatsapp",
    addy: "Ciputat Tangerang Selatan",
  },
];
