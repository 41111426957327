export default [
  {
    head: "Your company will go places, yet you can stay under our roof",
    subhead: "Tell us your needs!",
    text1: "Send us a message",
    text2: "Name",
    text3: "Email",
    text4: "Subject",
    text5: "Message",
    text6: "Send message",
    text7: "send attachment through our whatsapp"
  },
  {
    head: "Your company will go places, yet you can stay under our roof",
    subhead: "Tell us your needs!",
    text1: "Kirimi kami pesan",
    text2: "Nama",
    text3: "Email",
    text4: "Subjek",
    text5: "Pesan",
    text6: "Kirim Pesan",
    text7: "Kirim gambar melalui whatsapp kami"
  },
];
